<template>
  <v-row>
    <v-col>
      <v-alert
        text
        prominent
        dense
      >
        <v-row
          align="center"
          no-gutters
        >
          <v-col class="grow">
            Telegram Notification: <span
              class="font-weight-bold"
              :class="user.telegram_chat_id? 'green--text' :'orange--text'"
            >{{ user.telegram_chat_id ? 'Active' : 'Inactive' }}</span>
          </v-col>
          <v-col class="shrink">
            <v-btn
              icon
              to="/profile"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-card :loading="loading">
        <v-toolbar flat>
          <v-icon>mdi-bell</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-btn-toggle
            v-model="notificationStatus"
            mandatory
            borderless
            dense
          >
            <v-btn>
              All
            </v-btn>
            <v-btn>
              Unread
            </v-btn>
          </v-btn-toggle>
          <v-spacer />
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="markAllAsRead()">
                <v-list-item-title>Mark all as read</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-slide-y-transition>
          <v-list
            v-if="items.length"
            three-line
            class="pb-0"
          >
            <template v-for="(item, index) in items">
              <v-list-item
                :key="item.id"
                :class="[item.read_at? readColor : '']"
                @click="goToDetail(item)"
              >
                <v-list-item-content>
                  <span class="text--secondary caption">{{ $options.filters.formatDateTime(item.created_at) }}</span>
                  <v-list-item-title
                    :class="[!item.read_at? 'font-weight-bold' : '']"
                    v-text="item.payload.title"
                  />
                  <v-list-item-subtitle
                    class="text--primary"
                    :class="[!item.read_at? 'font-weight-bold' : '']"
                    v-text="item.payload.body"
                  />
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip
                    v-if="!item.read_at"
                    left
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="readNotification(item.id)"
                      >
                        <v-icon>mdi-bell-check-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Mark as read</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else
                    left
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="unreadNotification(item.id)"
                      >
                        <v-icon>mdi-bell-cancel-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Mark as unread</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-divider
                v-if="index + 1 < items.length"
                :key="index"
              />
            </template>
          </v-list>
          <div
            v-if="items.length == 0 && !loading"
            class="d-flex flex-column justify-center align-center"
            style="height:50vh"
          >
            <div class="d-block">
              <v-icon size="80">
                mdi-bell-remove
              </v-icon>
            </div>
            <div class="d-block title font-italic font-weight-light">
              No notifications available
            </div>
          </div>
        </v-slide-y-transition>
      </v-card>
      <div class="text-center mt-5">
        <!-- pagination use vmodel in mixin -->
        <v-pagination
          v-if="items.length"
          v-model="options.page"
          :length="meta.last_page"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import DatalistMixin from '../../mixins/DatalistMixin';

export default {
  name: 'NotificationList',
  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '200px',
        },
        {
          text: 'Device',
          value: 'device',
        },
        {
          text: 'Trigger When',
          value: 'when',
        },
        {
          text: 'Organization',
          value: 'organization',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '170px',
        },
      ],
      filter: {
        status: null,
        page: 1,
      },
      notificationStatus: null,
    };
  },

  computed: {
    readColor() {
      return this.$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3';
    },

    user() {
      return this.$store.state.profile.user;
    },
  },

  watch: {
    notificationStatus(state) {
      if (state === 0) this.filter.status = null;
      if (state === 1) this.filter.status = 'unread';
      this.$router.push(this.routeGenerator()).catch((err) => { });
    },
  },

  methods: {
    getData() {
      return this.$api.getNotifications({
        ...this.filter,
      });
    },

    async markAllAsRead() {
      this.loading = true;
      try {
        await this.$api.readAllNotifications();
        this.$store.dispatch('getProfile');
        this.getItem();
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed Read All Notifications');
      }
    },

    async readNotification(notificationId) {
      try {
        const read = this.items.find((item) => item.id === notificationId);
        if (read) read.read_at = new Date().toISOString();
        await this.$api.readNotification(notificationId);
        this.$store.dispatch('getProfile');
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed Read Notification');
      }
    },

    async unreadNotification(notificationId) {
      try {
        const read = this.items.find((item) => item.id === notificationId);
        if (read) read.read_at = null;
        await this.$api.unreadNotification(notificationId);
        this.$store.dispatch('getProfile');
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed Unread Notification');
      }
    },

    goToDetail({
      id,
      payload: {
        type, typeable_id,
      } = {},
    }) {
      this.menu = false;
      this.readNotification(id);
      if (type === 'device') this.$router.push(`/devices/${typeable_id}`).catch(() => { });
      else this.$toast.warning('Can\'t fetch notification data');
    },
  },
};
</script>
